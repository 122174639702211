<template>
  <div id="LOCATION_BADGE_GROUP_MODAL" style="height: 100%; width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Location"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.location`) }}</label>
        <button class="btn-action" @click="locationSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="locations">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="locationBadgeClick(item.uuId)"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('badgeGroupError') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!-- location selector -->
    <GenericSelectorModalForAdmin v-if="locationSelectorShow"
      :show.sync="locationSelectorShow" 
      :entityService="locationUtil" 
      entity="LOCATION"
      nonAdmin
      :preselected="locationEditId"
      :singleSelection="!multiple"
      @cancel="locationSelectorCancel"
      @ok="locationSelectorOk"
    />

  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { fieldValidateUtil } from '@/script/helper-field-validate'
import { locationUtil } from '@/views/management/script/location';

export default {
  name: 'LocationBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    show: { type: Boolean, required: true }
    , locationList: { type: Array, default: () => [] }
    , multiple: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false
      , locationSelectorShow: false
      , locations: []
      , locationEditId: null
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil
    this.modifiedList = []
    this.locationUtil = locationUtil
  },
  beforeMount() {
    this.locations.splice(0, this.locations.length, ...this.locationList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.fieldValidateUtil = null
    this.locationUtil = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.errors.clear()
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.locations.splice(0, this.locations.length, ...this.locationList)
        }
      }
    }
  },
  computed: {
    showError() {
      return fieldValidateUtil.hasError(this.errors, 'badgeGroupError');
    },
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , locationSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.locationSelectorShow = false
    }
    , locationBadgeRemove: function(index) {
      this.locations.splice(index,1)
    }
    , locationBadgeClick: function(id) {
      this.locationEditId = id
      this.locationSelectorShow = true
    }
    , locationSelectorToggle() {
      this.locationEditId = null
      this.locationSelectorShow = true
    }
    , locationSelectorOk({ details }) {
      const locations = JSON.parse(JSON.stringify(this.locations));
      if (this.multiple) {
        if (details.length > 0) {
          const newLocationsToReplacePreselected = [];
          for (const r of details) {
            const foundIndex = locations.findIndex(i => i.uuId == r.uuId);
            if (foundIndex > -1) {
              const location = locations[foundIndex];
              location.uuId = r.uuId;
              location.name = r.name;
              newLocationsToReplacePreselected.push(location);
              locations.splice(foundIndex, 1);
            } else {
              newLocationsToReplacePreselected.push({ uuId: r.uuId, name: r.name })
            }
          }
          
          if (this.locationEditId != null) {
            const foundIndex = locations.findIndex(i => i.uuId == this.locationEditId);
            if (foundIndex != -1) {
              locations.splice(foundIndex, 1, ...newLocationsToReplacePreselected);
            } else {
              locations.push(...newLocationsToReplacePreselected);
            }
          } else {
            locations.push(...newLocationsToReplacePreselected);
          }
        } else if (this.locationEditId != null) {
          //When no selection is made, remove the preselected one from the existing locations
          locations.splice(locations.findIndex(j => j.uuId === this.locationEditId), 1);
        }
      
        this.locations.splice(0, this.locations.length, ...locations);

        this.locationEditId = null;
      } else if (details.length > 0) {
        this.locations.splice(0, this.locations.length, { uuId: details[0].uuId, name: details[0].name });
      } else {
        this.locations.splice(0, this.locations.length);
      }
    }
    , ok() {
      this.errors.clear()
      if (!this.isOptional && this.locations.length < 1) {
        this.errors.add({
          field: 'badgeGroupError',
          msg: this.$i18n.t('error.missing_argument', [this.$t(`project.field.location`)])
        })
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          this.$emit('ok', objectClone(this.locations))
          this.$emit('update:show', false)
        }
      })
    }
  }
}
</script>